@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.public-page-header {
  display: flex;
  justify-content: center;

  &__container {
    padding: 115px 140px 75px 140px;
    max-width: 1440px;
    width: 100%;
  }

  img {
    aspect-ratio: 1/1;
  }

  &__heading {
    font-size: 40px;
    font-weight: 500;
    font-family: $header-font;
    color: $dark-blue;
  }

  .public-page-header__text {
    p {
      font-family: $header-font;
      color: $dark-blue;
      font-size: 24px;
      font-weight: 400;
      line-height: 35px;
    }
  }

  &__text-block {
    position: relative;
    height: 100%;
  }

  &__illustration {
    position: absolute;
    bottom: 0;
    right: -100px;
  }

  @include sm-screen {
    &__illustration {
      display: none;
    }
  }

  @include mobile-screen {
    &__container {
      padding: 70px 15px 50px 15px;
      gap: 24px;
    }

    &__text-block {
      text-align: left;
    }

    .btn {
      align-self: flex-start;
    }

    .public-page-header__text {
      p {
        font-size: var(--s1);
        line-height: 28px;
      }
    }
  }
}
