@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.home-page-header {
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -38px;
  z-index: 1000;

  .em {
    color: $primary-green;
  }

  &__container {
    max-width: $page-width;
    padding: 250px 140px;
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 620px;
  }

  &__title {
    font-size: 54px;
    color: $dark-blue;
    line-height: 60px;
    font-weight: 500;
    margin: 10px 0;
  }

  &__text {
    margin: 10px 0;

    &, & p {
      font-size: 24px !important;
      color: $dark-blue;
      line-height: 35px !important;
      font-weight: 400 !important;
    }
  }

  &__endorsement {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 70px;
  }

  &__endorsement-text {
    font-size: var(--s-1);
  }

  @include mobile-screen {
    //padding-block-start: 20px;
    //margin-bottom: 20px;
    //overflow: hidden;
    //
    &__container {
      //  max-width: 100%;
      padding-block-start: 100px;
    }

    &__title {
      font-size: 36px;
      line-height: 40px;
    }

    &__text {
      display: none;
    }


    &__endorsement-text {
      font-size: 12px;
    }

    &__endorsement-logo {
      width: 40px;
    }
  }

  @include md-screen {
    min-height: calc(100vh - 100px);
    &__endorsement {
      display: none;
    }
  }
}
