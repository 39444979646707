@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.banner-list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: var(--s3);
  width: 100%;

  &__container {
    width: 100%;
    justify-content: center;
    .center &.center {
      max-inline-size: 140ch;
    }
  }


  .switcher > &__text {
    flex-basis: fit-content;
    flex-grow: 0;
  }


  &__text {
    p {
      color: $dark-blue;
      font-family: $header-font;
      font-size: var(--s1);
      font-weight: 400;
      line-height: normal;
      margin: 0;
      text-align: center;

    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    gap: var(--s1);
    height: 100%;
    margin: 0;

    img {
      max-height: var(--s3);
      width: 100%;
    }
  }


  //@include mobile-screen {
  //  flex-direction: column;
  //  padding: 15px;
  //
  //  &__text {
  //    p {
  //      font-size: var(--s0);
  //      line-height: 24px;
  //    }
  //  }
  //
  //  &__container {
  //    flex-direction: column;
  //    align-items: flex-start;
  //    gap: 16px;
  //  }
  //
  //  ul {
  //    flex-wrap: wrap;
  //    justify-content: flex-start;
  //    gap: 16px;
  //    padding: 0 30px;
  //  }
  //}
}
