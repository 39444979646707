@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.blog-posts-footer {
  display: flex;
  flex-direction: column;
  margin: 0;

  &__title {
    color: $dark-blue;
    margin: 0;
  }

  &__posts {
    display: flex;
    justify-content: center;
    gap: var(--s1);
    padding: var(--s3) 0;
  }

  .read-more {
    margin: 0 auto;
    width: fit-content;
  }
}

