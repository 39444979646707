@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.value-list {
  display: flex;
  justify-content: center;
  gap: 30px;
  font-family: $header-font;
  font-size: var(--s0);
  font-weight: 400;
  color: $dark-blue;
  padding: 0 !important;

  &__value {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__title {
    font-size: var(--s1);
  }

  @include mobile-screen {
    flex-direction: column;
    padding: 5px 16px 30px 16px !important;

    &__title {
      font-size: var(--s0);
    }
  }
}
