@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;

.contact-am-block {
  font-family: $header-font;
  &__container {
    width: 100%;
    border-radius: var(--s1);
    color: $dark-blue;

    .switcher {
      align-items: center;
      justify-content: space-evenly;
    }

    .center & .center {
      line-height: normal;
      max-inline-size: 110ch;
    }
  }

  &__link {
    color: $dark-blue;

    font-weight: 500;
  }

  &__detail {
    display: flex;
    align-items: center;
    gap: var(--s-2);
    font-size: var(--s1);
  }

  &__info {
    flex: 1;

    i {
      font-size: var(--s1);
    }

    &--bigtext {
      &, p {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 500;

        a {
          text-decoration: none;
          color: #4e4e4e;
        }
      }
    }
  }

  &__footnote {
    font-size: 20px;
    font-weight: 500;
    .box & {
      color: rgba(103, 106, 108, 1);
    }
  }

  &__filler {
    img {
      max-width: 350px;
      min-width: 250px;
      width: 100%;
      padding: 60px 20px;
    }
  }

  .switcher > &__picture {
    flex-basis: min-content;
    flex-grow: 0;
  }

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;

    .amtext {
      font-weight: bold;
      font-size: 20px;
      line-height: 0.8;
      text-align: center;
      margin-bottom: 0;

      &--low {
        font-weight: normal;
        font-size: var(--s1);
        margin-top: 10px;
      }
    }

    img {
      border-radius: 50%;
      margin-bottom: 14px;
      width: 200px;
    }
  }

  h2 {
    font-size: var(--s3);
    font-weight: 500;
    margin-block: 0;
  }
  h3 {
    --space: var(--s-5);
  }
}

