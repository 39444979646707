@use "../settings/all" as *;
@use "screen-sizes";

@mixin cms-block() {
  display: flex;
  justify-content: center;


  ol, ul {
    font-size: 20px;
  }

  @include screen-sizes.mobile-screen {

    &__container {
      flex-direction: column;
    }

    p, ol, ul {
      font-size: var(--s0);
    }
  }
}
