@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "../../../components/portal/feedbacks/all";


.tutors {
  &__title {
    margin-bottom: var(--s3);
  }
}

.stack > .show-more {
  display: flex;
  justify-content: center;
  margin-block-start: var(--s3);
}

.tutor-list {

}

.tutor-card {
  flex-grow: 1;
  padding: var(--s3);
  background-color: white;
  box-shadow: 0px 0px 30px 0px #0000001A;
  border-radius: var(--s1);
  color: $dark-blue;

  &.switcher {
    --threshold: 40rem;
  }

  &.switcher > &__img {
    flex-grow: 0;
    flex-shrink: 1;
    max-width: 100%;
  }
  &.switcher > &__content-wrapper {

  }

  &__header {
    display: flex;
    align-items: center;
    gap: var(--s-2);
    margin: 0;

    & > span {
      font-family: $header-font;
      font-size: var(--s2);
      font-weight: 500;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--s0);
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--s0);
    justify-content: space-between;
    height: 100%;
  }

  &__details {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--s1);
  }

  &__text {
    font-size: var(--s0);
    font-weight: 700;
  }

  &__detail {
    display: flex;
    align-items: center;
    gap: var(--s-2);

    i {
      font-size: var(--s1);
    }
  }

  &__content-text {
    p {
      font-size: var(--s0);
      font-weight: 400;
    }
  }

  .teacher-profile__rating-wrapper {
    display: flex;
    align-items: center;
    gap: var(--s-2);

  }
  .teacher-profile__rating-number{
    font-size: var(--s-1);
  }

  .feedback-ratings__field {
    padding: 0;
  }

  &__heading {
    font-size: var(--s1);
    font-weight: 700;
  }

  &__actions {
    display: flex;
    gap: var(--s-1);
  }

  &__img {
    img {
      height: 16rem;
      width: 16rem;
      border-radius: 1.5rem;
    }
  }

}


.expandable-text {
  &__toggle {
    display: none;
  }
}

@include mobile-screen {
  .expandable-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    max-height: 100px;

    &--expanded {
      -webkit-line-clamp: unset;
      max-height: 1000px;
      transition: all 1.5s ease;
    }

    &__toggle {
      display: inline-block;
      font-size: var(--s0);
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }
  }
}



