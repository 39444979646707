@use "../../settings/all" as *;
@use "../../mixins/all" as *;

.suggested-blog-post {
  background-color: #EBF2F5;
  color: $dark-blue;
  border-radius: var(--s-2);
  flex: 1;
  padding: var(--s2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  gap: var(--s1);
  flex-basis: 0;
  overflow: hidden;

  .btn__primary {
    background-color: #C2DFF3;
    box-shadow: none;

    &:hover {
      background-color: #C2DFF3 !important;
      border-color: #C2DFF3 !important;
    }
  }

  &__body {
    flex: 1
  }

  &__image {
    border-radius: var(--s-2);
    width: 100%;
    height: auto;
  }

  &__title {
    font-weight: 700;
    line-height: 1.3;
    font-size: var(--s1);
    color: $dark-blue;
  }

  &__link {
    &:hover {
      text-decoration: none;
    }
  }

  &__peek {
    font-size: var(--s0);
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.suggested-blog-posts {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--s3);

  &__title {
    display: table;
    font-size: var(--s1);
    line-height: 1;
    background-color: $white;
    border-radius: var(--s-5);
    padding: var(--s0);
    text-align: center;
    width: 100%;
  }

  &--side {
    flex-direction: column;
  }
}

