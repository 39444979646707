@use "../../../settings/all" as *;
@use "../../../mixins/all" as *;
@use "sass:math";

.text-image-block {
  @include cms-block();
  &__container.switcher {
    align-items: center;
    flex-direction: row-reverse;
    --gutter: var(--s1);
    --threshold: 40rem;
    flex-wrap: wrap-reverse;


    &--reversed {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }



  &__img-wrapper {
    overflow: hidden;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__heading {
    padding: 20px 0 50px;

    &--sub {
      color: $dark-blue;
      margin-bottom: 22px;
      font-size: var(--s4);
      font-weight: 500;
    }

    &--descr {
      font-size: $text-size;
      line-height: 28px;
      color: $dark-blue;

      p {
        font-size: var(--s1);
        font-weight: 600;
      }

      ul {
        list-style: disc;
        padding-left: 40px;
      }
    }
  }

  &__button {
    margin-top: 50px;
  }

  &__img {
    --radius: 140px;
    height: 100%;
    object-fit: scale-down;
    border-top-right-radius: var(--radius);
    border-bottom-left-radius: var(--radius);


    &--cover {
      object-fit: cover;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--narrow {
      padding-inline: 220px;
    }
  }
}
